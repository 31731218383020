// normalize

html {
   line-height: 1.4;
   /* 1 */
   -webkit-text-size-adjust: 100%;
   /* 2 */
}

/* Sections
    ========================================================================== */

/**
  * Remove the margin in all browsers.
  */

*,
*::after,
*::before {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}


/**
  * Render the `main` element consistently in IE.
  */

main {
   display: block;
}

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */



/* Grouping content
    ========================================================================== */

/**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */

hr {
   box-sizing: content-box;
   /* 1 */
   height: 0;
   /* 1 */
   overflow: visible;
   /* 2 */
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

pre {
   font-family: monospace, monospace;
   /* 1 */
   font-size: 1em;
   /* 2 */
}

/* Text-level semantics
    ========================================================================== */

/**
  * Remove the gray background on active links in IE 10.
  */

a {
   background-color: transparent;
}

/**
  * 1. Remove the bottom border in Chrome 57-
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  */

abbr[title] {
   border-bottom: none;
   /* 1 */
   text-decoration: underline;
   /* 2 */
   text-decoration: underline dotted;
   /* 2 */
}

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */

b,
strong {
   font-weight: bolder;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

code,
kbd,
samp {
   font-family: monospace, monospace;
   /* 1 */
   font-size: 1em;
   /* 2 */
}

/**
  * Add the correct font size in all browsers.
  */

small {
   font-size: 80%;
}

/**
  * Prevent `sub` and `sup` elements from affecting the line height in
  * all browsers.
  */

sub,
sup {
   font-size: 75%;
   line-height: 0;
   position: relative;
   vertical-align: baseline;
}

sub {
   bottom: -0.25em;
}

sup {
   top: -0.5em;
}

/* Embedded content
    ========================================================================== */

/**
  * Remove the border on images inside links in IE 10.
  */

img {
   border-style: none;
}

/* Forms
    ========================================================================== */

/**
  * 1. Change the font styles in all browsers.
  * 2. Remove the margin in Firefox and Safari.
  */

button,
input,
optgroup,
select,
textarea {
   font-family: inherit;
   /* 1 */
   font-size: 100%;
   /* 1 */
   line-height: 1.15;
   /* 1 */
   margin: 0;
   /* 2 */
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
   /* 1 */
   overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
   /* 1 */
   text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */

button,
[type="button"],
[type="reset"],
[type="submit"] {
   -webkit-appearance: button;
}

/**
  * Remove the inner border and padding in Firefox.
  */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
   border-style: none;
   padding: 0;
}

/**
  * Restore the focus styles unset by the previous rule.
  */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
   outline: 1px dotted ButtonText;
}

/**
  * Correct the padding in Firefox.
  */

fieldset {
   padding: 0.35em 0.75em 0.625em;
}

/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */

legend {
   box-sizing: border-box;
   /* 1 */
   color: inherit;
   /* 2 */
   display: table;
   /* 1 */
   max-width: 100%;
   /* 1 */
   padding: 0;
   /* 3 */
   white-space: normal;
   /* 1 */
}

/**
  * Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */

progress {
   vertical-align: baseline;
}

/**
  * Remove the default vertical scrollbar in IE 10+.
  */

textarea {
   overflow: auto;
}

/**
  * 1. Add the correct box sizing in IE 10.
  * 2. Remove the padding in IE 10.
  */

[type="checkbox"],
[type="radio"] {
   box-sizing: border-box;
   /* 1 */
   padding: 0;
   /* 2 */
}

/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
   height: auto;
}

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */

[type="search"] {
   -webkit-appearance: textfield;
   /* 1 */
   outline-offset: -2px;
   /* 2 */
}

/**
  * Remove the inner padding in Chrome and Safari on macOS.
  */

[type="search"]::-webkit-search-decoration {
   -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */

::-webkit-file-upload-button {
   -webkit-appearance: button;
   /* 1 */
   font: inherit;
   /* 2 */
}

/* Interactive
    ========================================================================== */

/*
  * Add the correct display in Edge, IE 10+, and Firefox.
  */

details {
   display: block;
}

/*
  * Add the correct display in all browsers.
  */

summary {
   display: list-item;
}

/* Misc
    ========================================================================== */

/**
  * Add the correct display in IE 10+.
  */

template {
   display: none;
}

/**
  * Add the correct display in IE 10.
  */

[hidden] {
   display: none;
}

button {
   width: fit-content;
   border: none;
   background-color: inherit;
}

// page styles

body {
   min-height: 100vh;
   font-family: 'Inter', sans-serif;
   background: #F5F7FB;
}

.mainPage-btn {
   border-radius: 15px;
   font-weight: 500;
   color: #F5F7FB;
   background: #4D5B9E;
   cursor: pointer;
}

.topBlob,
.bottomBlob {
   position: fixed;
   z-index: -1;
}

.topBlob {
   top: 0%;
   right: 0;
}

.bottomBlob {
   bottom: 0;
   left: 0;
}

.container {
   width: min(100% - 2rem, 800px);
   margin: auto;
}

.introPage-container {
   display: grid;
   align-content: center;
   justify-items: center;
   min-height: 100vh;
   text-align: center;

   &__title {
      font-family: 'Karla', sans-serif;
      color: #293264;
   }

   &__pr {
      margin-top: 10px;
      font-weight: 500;
      color: #293264;
   }

   &__btn {
      padding: 15px 28px;
      margin-top: 22px;
   }
}

.quizPage {
   display: grid;
   align-content: center;
   justify-items: center;
   min-height: 100vh;
   &__check-btn {
      padding: 10px 22px;
      margin-block: 1rem;
   }
   &__playAgain-btn {
      padding: 10px 22px;
      margin-block: 1rem;
      margin-left: 1rem;
   }
   &__score {
      font-weight: bold;
      color: #293264;
   }

}
.quizItem {
   width: 100%;
   padding-block: 15px;
   border-bottom: 1px solid #DBDEF0;
   text-align: center;

   &__question {
      margin-bottom: 12px;
      font-size: 1.2rem;
      font-family: karla, sans-serif;
      font-weight: 700;
      letter-spacing: .2px;
      color: #293264;
   }
   &__answers {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
   }
   &__answer {
      padding: 6px 19px;
      border: 1px solid #4D5B9E;
      border-radius: 100vw;
      font-size: 1rem;
      font-weight: 500;
      text-transform: capitalize;
      color: #293264;
      cursor: pointer;

      &--selected {
         border-color: transparent;
         background-color: #D6DBF5;
      }
      &--correct {
         border-color: transparent;
         background-color: #94D7A2;
      }
      &--unhighlighted {
         opacity: .5;
      }
      &--wrong {
         border-color: transparent;
         background-color: #F8BCBC;
         opacity: .5;
      }
   }
}